.dashboard-root .centered-empty-section {
  display: flex;
  flex-direction: column;
  height: 70px;
  justify-content: center;
  margin-top: 50px;
}

.approved-date {
  color: var(--yellowgreen);
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 15px;
  top: 25px;
  width: 350px;
}

.last-updated{
  display: flex;
  flex-direction: column;
  font-style: italic;
  position: absolute;
  right: 15px;
  width: 350px;
}