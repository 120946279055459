.search-popover-root:hover {
  color: var(--link-color);
}

.search-popover-button {
  background: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  margin-bottom: 3px;
  padding: 0 16px;
}
