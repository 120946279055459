.incentives-reviews-summary-container {
    align-items: center;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 15px;
    padding: 25px 15px;
    width: 100%;
}

.incentives-reviews-summary-container .incentives-reviews-summary-statistic {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;
    max-width: 200px;
    min-width: 205px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
}

.incentives-reviews-summary-container .incentives-reviews-summary-statistic .ant-statistic-title {
    color: black;
    font-weight: bolder;
}

.incentives-reviews-summary-container .incentives-reviews-summary-statistic .ant-statistic-content .ant-statistic-content-value {
    color: var(--orange);
    font-size: xx-large;
    font-weight: bolder;
}

.incentives-reviews-summary-container .incentives-reviews-summary-content-approved {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-content-approved .incentives-reviews-summary-progress {
    padding-top: 10px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-button-container {
    display: flex;
    justify-content: center;
    margin-top: 25px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-lock-timestamp {
    color: var(--dark-gray);
    margin-top: 25px;
    text-align: center;
}

.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-summary-button button,
.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-summary-button {
    height: 50px;
    width: 50px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-lock-button,
.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-unlock-button {
    margin-right: 7.5px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-download-button {
    margin-left: 7.5px;
}

.incentives-reviews-summary-container .incentives-reviews-summary-button-container .incentives-reviews-summary-button span svg {
    height: 25px;
    width: 25px;
}
