.agent-selector-root {
  width: 300px;
}

.agent-selector-checkbox {
  border: 1px solid var(--gray-border-color);
  border-left: none;
  margin-left: -1px;
  padding: 4px 10px;
}
