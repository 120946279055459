.search-bar-root {
  max-width: 100%;
}

.search-bar-root.search-bar-medium {
  width: 500px;
}

.search-bar-root.search-bar-small {
  width: 300px;
}
