.commission-reviews-table-summary-container {
  margin-bottom: 15px;
  width: 1080px;
}

.commission-reviews-table-summary-container .ant-collapse-header {
  background-color: var(--light-gray);
}

.commission-reviews-table-summary-container .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  margin-top: 35px;
}

.commission-reviews-table-summary-container .ant-collapse-content-box {
  padding: 0;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content {
  display: flex;
  justify-content: flex-end;
}

.commission-reviews-table-summary-container .ant-collapse-extra {
  width: 100%;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic {
  display: flex;
  flex-direction: column;
  width: 170px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-statistic .ant-statistic-title,
.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic .ant-statistic-title {
  color: black;
  font-weight: bolder;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-statistic.total-commission-statistic {
  margin-right: 75px;
  width: 150px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-statistic.agent-count-statistic {
  margin-right: 75px;
  width: 50px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic.manager-name-statistic {
  margin-right: 15px;
  width: 190px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-statistic .ant-statistic-content .ant-statistic-content-value {
  color: var(--orange);
  font-weight: bolder;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic .ant-statistic-content-value {
  font-size: medium;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic.manager-name-statistic .ant-statistic-content-value {
  white-space: pre-line;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-team-statistic.team-name-statistic {
  margin-right: 60px;
  width: 190px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .ant-statistic-content {
  display: flex;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-content-approved {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 75px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 20px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-button-container button {
  height: 50px;
  width: 50px;
}

.commission-reviews-table-summary-container .commission-reviews-table-summary-main-content .commission-reviews-table-summary-button-container svg {
  height: 25px;
  width: 25px;
}
