.bonus-section-root {
  position: relative;
}

.bonus-section-empty {
  text-align: center;
}

.bonus-section-bonuses {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.bonus-section-card {
  margin-top: 24px;
  min-width: 330px;
}

.bonus-section-card .ant-card-head {
  background: var(--light-gray);
}

.bonus-section-card .ant-card-body {
  display: flex;
}

.bonus-section-line-items {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.bonus-section-line-item {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 2px 0;
}

.bonus-section-line-value {
  padding-left: 5px;
}

.bonus-section-progress-item {
  padding-left: 25px;
}
