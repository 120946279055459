.incentive-form-space{
    display: flex;
    margin-bottom: 8px;
}

.added-incentive-fields{
    max-height: 40vh;
    overflow-y: auto;
}

.incentive-field-input{
    width: 210px;
}

.automated-incentive{
    color: green;
    margin-left: 380px;
}

.is-multiplier-checkbox{
    margin-left: 22px;
}