.commission-reviews-summary-container {
  align-items: center;
  border: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 15px;
  padding: 25px 15px;
}

.commission-reviews-summary-container .commission-reviews-summary-statistic {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}

.commission-reviews-summary-container .commission-reviews-summary-statistic .ant-statistic-title {
  color: black;
  font-weight: bolder;
}

.commission-reviews-summary-container .commission-reviews-summary-statistic .ant-statistic-content .ant-statistic-content-value {
  color: var(--orange);
  font-size: xx-large;
  font-weight: bolder;
}

.commission-reviews-summary-container .commission-reviews-summary-content-approved {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.commission-reviews-summary-container .commission-reviews-summary-content-approved .commission-reviews-summary-progress {
  padding-top: 10px;
}

.commission-reviews-summary-container .commission-reviews-summary-button-container {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.commission-reviews-summary-container .commission-reviews-summary-lock-timestamp {
  color: var(--dark-gray);
  margin-top: 25px;
  text-align: center;
}

.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-summary-button button,
.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-summary-button {
  height: 50px;
  width: 50px;
}

.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-lock-button,
.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-unlock-button {
  margin-right: 7.5px;
}

.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-download-button {
  margin-left: 7.5px;
}

.commission-reviews-summary-container .commission-reviews-summary-button-container .commission-reviews-summary-button span svg {
  height: 25px;
  width: 25px;
}
