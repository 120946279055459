.commission-table-footer {
  display: flex;
  justify-content: flex-end;
}

.commission-table-footer-cell {
  font-weight: 500;
}

.commission-table-footer-cell-label {
  padding-right: 10px;
}