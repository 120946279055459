.payment-totals-root {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
}

.payment-totals-block {
  margin-top: 24px;
  max-width: 100%;
  width: 350px;
}

.payment-totals-bold {
  font-weight: bold;
}

.payment-totals-separater {
  font-size: 28px;
  font-weight: bold;
  margin-top: 24px;
  padding: 0 20px;
}

.payment-totals-final-sum {
  font-size: 16px;
  font-weight: bolder;
  margin-top: 12px;
  padding: 12px 16px 12px 0;
}

.payment-totals-panel-header {
  font-size: 16px;
  font-weight: bolder;
}

.payment-totals-line-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.payment-totals-value {
  padding-left: 15px;
}

.payment-totals-commission-total-block .ant-card-body {
  padding: 11px 15px;
}

.payment-totals-commission-total-block .payment-totals-commission-total-label {
  font-size: larger;
  font-weight: 700;
  margin-right: 15px;
}

.payment-totals-commission-total-block .payment-totals-commission-total-amount {
  color: var(--orange);
  font-size: large;
}