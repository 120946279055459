.incentives-table .incentives-action-cell .incentives-action-cell-buttons button {
    margin-right: 10px;
}

.incentives-table .incentives-action-cell {
    display: flex;
}

.incentives-table .incentives-action-cell .incentives-action-cell-buttons {
    align-items: center;
    display: flex;
}

.incentives-table .incentives-footer {
    display: flex;
}

.incentives-table .incentives-action-cell .incentives-action-cell-error-message {
    color: var(--red);
}

.incentives-table .incentives-footer .incentives-footer-cell {
    width: 50%;
}

.incentives-table .incentives-footer .incentives-footer-total {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    text-align: right;
}

.incentives-table .incentives-footer .incentives-footer-total .incentives-footer-total-label {
    font-weight: 600;
}

.incentives-table .ant-form-item-explain-error {
    display: none;
}

.original-amount-label{
    font-size: smaller;
}

.check-icon{
    color: green;
    margin-left: 45%;
}
