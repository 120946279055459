.subheader-root {
  align-items: center;
  display: flex;
  padding-bottom: 12px;
  padding-top: 8px;
}

.subheader-heading {
  font-size: 24px;
  font-weight: bold;
}

.subheader-spacing {
  padding-right: 8px;
}

.subheader-separator {
  padding: 0 4px;
}

.subheader-label {
  font-weight: bold;
  padding-left: 4px;
  padding-right: 8px;
}

.subheader-selector {
  margin: 0 8px;
}
