.incentives-review-root {
  display: flex;
}

.incentives-review-root .summary-widget {
  margin-right: 25px;
  max-width: 300px;
  min-width: 200px;
  width: 15%;
}

.incentives-review-root .incentives-table-container{
  width: 85%;
}

.add-new-agent-incentive-button{
  margin-bottom: 25px;
}

.incentives-management-title{
  font-size: x-large;
  font-weight: bold;
}

.exclude-unpublished {
  margin-left: 20px;
}