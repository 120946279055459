.new-agent-selector-main-container{
    display:flex;
    flex-direction: column;
}

.override-container{
    display:flex;
}

.override-label{
    display:flow-root;
    margin: 35px 0 0 12px;
}

.per-unit-label{
    display:flow-root;
    font-weight: bold;
    margin: 35px 0 0 12px;
}