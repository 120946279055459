.nav-root {
  height: 50px;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.nav-root .nav-menu {
  background-color: var(--light-gray);
  display: block;
  text-align: right;
}

.nav-root .nav-menu > .nav-menu-not-clickable {
  pointer-events: none;
}

.nav-menu .nav-menu-left {
  float: left;
  text-align: left;
}

.nav-menu .nav-menu-no-hover {
  color: unset !important;
}

.nav-menu .nav-menu-no-hover::after {
  border-bottom: 0 !important;
}

.nav-menu .nav-menu-left span {
  margin-right: 10px;
}
