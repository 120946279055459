.incentive-reviews-table-summary-container {
    margin-bottom: 15px;
    width: 100%;
}

.incentive-reviews-table-summary-container .ant-collapse-content-box {
    padding: 0;
}

.incentive-reviews-table-summary-container .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    margin-top: 35px;
}

.incentive-reviews-table-summary-container .ant-collapse-extra {
    width: 100%;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content {
    display: flex;
    justify-content: flex-end;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-statistic {
    display: flex;
    flex-direction: column;
    width: 170px;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .ant-statistic-content {
    color: var(--orange);
    display: flex;
    font-weight: bolder;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-statistic .ant-statistic-title,
.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-team-statistic .ant-statistic-title {
    color: black;
    font-weight: bolder;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-team-statistic .ant-statistic-content-value {
    color: black;
    font-size: medium;
    font-weight: normal;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-team-statistic.team-name-statistic {
    margin-right: 2vw;
    width: 20vw;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-team-statistic.manager-name-statistic {
    margin-right: 5vw;
    width: 20vw;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-statistic.total-incentive-statistic{
    margin-right: 3vw;
    min-width: 105px;
    width: 20vw;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-statistic.agent-count-statistic{
    margin-right: 5vw;
    width: 7vw;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .approval-progress{
    margin-right: 2vw;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 20px;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-button-container button {
    height: 50px;
    width: 50px;
}

.incentive-reviews-table-summary-container .incentive-reviews-table-summary-main-content .incentive-reviews-table-summary-button-container svg {
    height: 25px;
    width: 25px;
}

