.container {
    border-color: lightgray;
    border-radius: 30px;
    border-style: solid;
    border-width: thin;
    display: flex;
    margin-top: 10px;
    vertical-align: baseline;
}

.container .is-multiplier{
    color: green;
    width: 74px;
}

.container .cursor {
    cursor: grab;
    display: flex;
    margin-left: 15px;
    margin-top: 80%;
}

.container .added-incentive-field {
    display: flex;
    max-height: 35px;
    width: 365px;
}

.container .added-incentive-field .type {
    margin-left: 8px;
}