:root {
  --black: #002a3a;
  --dark-blue: #00445f;
  --blue: #005e85;
  --dark-gray: #a1aaad;
  --green: #00856a;
  --yellowgreen: #9acd32;
  --gray: #ddd;
  --light-gray: #fafafa;
  --off-white: #f5f5f5;
  --orange: #fd7f20;
  --light-orange: #ffa400;
  --red: #ff4848;
  --teal: #4ac1e0;
  --white: #fff;
  --faded-white: rgb(255 255 255 / 50%);
  --link-color: #1890ff;
  --gray-border-color: #ddd;
  --dark-gray-color: #6f7172;
}

span.ant-divider-inner-text {
  color: var(--dark-blue);
}

div.ant-divider-horizontal.ant-divider-with-text::before,
div.ant-divider-horizontal.ant-divider-with-text::after {
  border-top: 1px solid var(--dark-blue);
}
