.section-root {
  padding: 12px 24px;
}

.section-main {
  position: relative;
}

.section-loading {
  align-items: center;
  background: var(--faded-white);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 500;
}

.section-content {
  padding: 8px 16px;
}
