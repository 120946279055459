.payroll-stats-container {
  margin-top: 20px;
}

.payroll-stats-container .payroll-stats-collapse-card-stats-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.payroll-stats-container .payroll-stats .payroll-stats-collapse .payroll-stats-collapse-header .ant-collapse-header {
  color: var(--blue);
  padding-left: 25px;
}

.payroll-stats-collapse-header .ant-space {
  display: block;
}

.payroll-stats-container .payroll-stats .payroll-stats-collapse .payroll-stats-collapse-header .ant-collapse-content-box {
  padding-left: 0;
  padding-right: 0;
}

.payroll-stats-collapse-card .ant-card-body {
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
  padding-top: 5px;
}

.payroll-stats-collapse-card .payroll-stats-collapse-card-team-name {
  align-items: center;
  display: flex;
  font-weight: 600;
  width: 50%;
}

.payroll-stats-collapse-card .payroll-stats-collapse-card-stats {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.payroll-stats-collapse-card-stats-bar {
  padding-top: 15px;
}

.payroll-stats-collapse-card-stats-totals.header-bottom {
  padding-top: 12px;
}
