.search-details-root {
  padding-bottom: 24px;
}

.search-details-bold,
.search-details-root .ant-table-row .search-details-bold.ant-table-cell.ant-table-cell-row-hover {
  color: var(--orange);
  font-weight: bold;
}

.search-details-row {
  padding-top: 24px;
}

.search-details-table {
  padding-top: 12px;
}

.search-details-basic-info,
.search-details-contribution-details .ant-descriptions {
  margin-bottom: 24px;
}

.search-details-basic-info .ant-descriptions-item-label {
  width: 16.7%;
}

.search-details-contribution-details .ant-descriptions-item-label {
  width: 25%;
}

.search-details-retail-rate-details .ant-descriptions-item-label {
  width: 25%;
}

.search-details-calculation-section .ant-descriptions-item-content {
  width: 33.5%
}

.retention-details-basic-info-first-row .ant-descriptions-item-label,
.retention-details-basic-info-second-row .ant-descriptions-item-label {
  width: 25%
}

.retention-details-basic-info-second-row {
  margin-top: 24px;
}

.retention-details-save-details-row .ant-descriptions-item-label {
  width: 50%
}

.description-table-left-margin{
  margin-left: 25px;
}
