.adjustments-table .corrections-and-adjustments-action-cell .corrections-and-adjustments-action-cell-buttons button {
  margin-right: 10px;
}

.adjustments-table .corrections-and-adjustments-action-cell {
  display: flex;
}

.adjustments-table .corrections-and-adjustments-action-cell .corrections-and-adjustments-action-cell-buttons {
  align-items: center;
  display: flex;
}

.adjustments-table .corrections-and-adjustments-footer {
  display: flex;
}

.adjustments-table .corrections-and-adjustments-action-cell .corrections-and-adjustments-action-cell-error-message {
  color: var(--red);
}

.adjustments-table .corrections-and-adjustments-footer .corrections-and-adjustments-footer-cell {
  width: 50%;
}

.adjustments-table .corrections-and-adjustments-footer .adjustments-footer-total {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  text-align: right;
}

.adjustments-table .corrections-and-adjustments-footer .adjustments-footer-total .adjustments-footer-total-label {
  font-weight: 600;
}

.adjustments-table .ant-form-item-explain-error {
  display: none;
}
