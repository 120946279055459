.contests-action-cell-buttons button {
    margin-right: 10px;
}

.contests-action-cell {
    display: flex;
    float: right;
}

.contests-action-cell-error-message {
    color: var(--red);
}
