.tags{
    margin-top: 15px;
    max-height: 30vh;
    overflow-y: auto;
}

.tags .ant-tag {
    height: 25px;
    margin-top: 5px;
}

.tags .ant-tag span {
    vertical-align: middle;
}