.search-modal-root .ant-modal-content {
  height: 90vh;
  min-height: 90vh;
  overflow: hidden;
}

.search-modal-root .ant-modal-content .ant-modal-close {
  position: fixed;
  right: 2.5%;
  top: 5%;
  z-index: 200;
}

.search-modal-root .ant-modal-content .ant-modal-header {
  background-color: var(--light-gray);
  height: 55px;
  position: fixed;
  width: 95%;
  z-index: 100;
}

.search-modal-root .ant-modal-content .ant-modal-body {
  height: calc(100% - 55px);
  overflow: auto;
  position: absolute;
  top: 55px;
  width: 100%;
}

.search-modal-loading {
  align-items: center;
  background: var(--faded-white);
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}
