.approval-progress-bar-container {
  display: flex;
}

.approval-progress-bar-container .approval-progress-bar-main {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.approval-progress-bar-container .approval-progress-bar-sub-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 8px;
}

.approval-progress-bar-container .approval-progress-bar-sub-container .approval-progress-bar-sub {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.approval-progress-bar-container .approval-progress-bar-sub-container .approval-progress-bar-sub-title,
.approval-progress-bar-container .approval-progress-bar-main .approval-progress-bar-title {
  font-weight: 500;
}
